import React, { useState, useEffect, useCallback } from 'react';
import SearchBar from './SearchBar';
import SuggestionsList from './SuggestionsList';
import Cart from './Cart';
import TotalPrice from './TotalPrice';
import PaymentOptions from './PaymentOptions';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';
import ConfirmationModal from './ConfirmationModal';

function SalesPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cashAmount, setCashAmount] = useState('');
    const [change, setChange] = useState(null);
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchSuggestions = useCallback(async (searchTerm) => {
        if (!token) return;

        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${searchTerm}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch suggestions');
            }

            const data = await response.json();
            setSuggestions(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching product suggestions:', error);
            setError(`Ошибка при получении предложений: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            fetchSuggestions(searchTerm);
        } else {
            setSuggestions([]);
        }
    }, [searchTerm, fetchSuggestions]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const addProductsToCart = (products) => {
        if (!Array.isArray(products)) return;

        products.forEach(product => {
            const existingItem = cartItems.find(item => item.id === product.id);
            const price = parseFloat(product.retailPrice);

            if (isNaN(price)) {
                console.error('Invalid price:', product.retailPrice);
                setError('Ошибка в цене товара');
                return;
            }

            if (existingItem) {
                setCartItems(cartItems.map(item =>
                    item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
                ));
            } else {
                setCartItems([...cartItems, { ...product, quantity: 1 }]);
            }
            setTotal(prevTotal => parseFloat((prevTotal + price).toFixed(2)));
        });
    };

    const handleRemoveItem = (item) => {
        const price = parseFloat(item.retailPrice);
        const updatedCartItems = cartItems.filter(cartItem => cartItem.id !== item.id);
        setCartItems(updatedCartItems);
        setTotal(prevTotal => parseFloat((prevTotal - price * item.quantity).toFixed(2)));
    };

    const handlePayment = async (force = false) => {
        if (!paymentMethod) {
            setError('Выберите тип оплаты');
            return;
        }

        if (cartItems.length === 0) {
            setError('Корзина пуста');
            return;
        }

        if (paymentMethod === 'cash') {
            const cashEntered = parseFloat(cashAmount);
            if (isNaN(cashEntered) || cashEntered < total) {
                setError('Недостаточно наличных');
                return;
            }
            setChange(`Сдача: ${(cashEntered - total).toFixed(2)} ₽`);
        }

        setError(null);
        setSuccessMessage(null);

        const payload = {
            payType: paymentMethod,
            items: cartItems.map(item => ({
                productId: item.id,
                quantity: item.quantity
            })),
            force: force
        };

        try {
            const response = await fetch(`${API_ENDPOINTS.SELL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error) {
                    throw new Error(errorData.error);
                }
                throw new Error('Ошибка при обработке запроса');
            }

            const data = await response.json();
            setSuccessMessage(`Продажа успешно обработана. Итоговая сумма: ${data['totalPrice']}`);

            setCartItems([]);
            setTotal(0);
            setPaymentMethod('');
            setCashAmount('');
            setChange(null);
        } catch (error) {
            console.error('Ошибка при обработке продажи:', error);
            setError(error.message);
            setIsModalOpen(true);
        }
    };

    const handleCashAmountChange = (e) => {
        setCashAmount(e.target.value);
        if (paymentMethod === 'cash') {
            const cashEntered = parseFloat(e.target.value);
            if (!isNaN(cashEntered) && cashEntered >= total) {
                setChange(`Сдача: ${(cashEntered - total).toFixed(2)} ₽`);
            } else {
                setChange('Введите корректную сумму наличных');
            }
        }
    };

    const changeColorClass = () => {
        if (change === null) return '';
        return change.startsWith('Введите корректную сумму') ? 'text-red-600' : 'text-green-600';
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleModalConfirm = async () => {
        setIsModalOpen(false);
        await handlePayment(true);
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log('Enter key pressed');
            const barcode = searchTerm.trim();
            if (barcode) {
                try {
                    const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${barcode}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                    console.log('Fetch response:', response);
                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.error || 'Failed to fetch product');
                    }

                    const products = await response.json();
                    console.log('Fetched products:', products);
                    if (Array.isArray(products) && products.length === 1) {
                        addProductsToCart(products);
                        setSearchTerm('');
                        setSuggestions([]);
                    }
                } catch (error) {
                    console.error('Error fetching products by barcode:', error);
                    setError(`Ошибка при получении товара: ${error.message}`);
                }
            }
        }
    };

    return (
        <div className="flex h-screen p-4">
            <div className="flex flex-col w-1/2 p-4 items-center relative">
                <div className="w-full absolute">
                    <SearchBar
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyPress}
                    />
                    <SuggestionsList
                        suggestions={suggestions}
                        onSelect={addProductsToCart}
                        searchTerm={searchTerm}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="flex flex-col w-1/2 p-4 bg-gray-100 rounded-lg shadow-lg ml-4">
                <Cart items={cartItems} onRemove={handleRemoveItem} />
                <TotalPrice total={total} />
                {paymentMethod === 'cash' && (
                    <div className="mt-4">
                        <label htmlFor="cash-input" className="block text-lg font-medium mb-2">Введите сумму наличными:</label>
                        <input
                            id="cash-input"
                            type="number"
                            value={cashAmount}
                            onChange={handleCashAmountChange}
                            className="w-full p-2 border rounded-lg"
                            placeholder="0.00"
                        />
                        {change !== null && (
                            <p className={`text-5xl mt-2 ${changeColorClass()}`}>{change}</p>
                        )}
                    </div>
                )}
                <PaymentOptions
                    onPayment={handlePayment}
                    onMethodSelect={(method) => {
                        setPaymentMethod(method);
                        if (method !== 'cash') {
                            setChange(null);
                        }
                    }}
                    selectedMethod={paymentMethod}
                />
                {error && <div className="text-red-500 mt-4">{error}</div>}
                {successMessage && <div className="text-green-500 mt-4">{successMessage}</div>}
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onConfirm={() => handleModalConfirm()}
                errorMessage={error}
            />
        </div>
    );
}

export default SalesPage;
