import React from 'react';

function TotalPrice({ total }) {
    return (
        <div className="flex justify-between items-center mt-4 text-xl font-semibold">
            <span>Итого:</span>
            <span>{total}₽</span>
        </div>
    );
}

export default TotalPrice;
