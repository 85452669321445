import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaBox, FaFileAlt, FaUsers, FaSignOutAlt, FaStore, FaChevronDown, FaClipboardList } from 'react-icons/fa';

const Sidebar = ({ width, onLogout }) => {
    const [selectedStore, setSelectedStore] = useState('Звенигородская');
    const location = useLocation();

    const handleStoreChange = (event) => {
        setSelectedStore(event.target.value);
    };

    return (
        <div
            className="bg-gray-800 text-white fixed top-0 left-0 h-full flex flex-col"
            style={{ width: `${width}px` }}
        >
            <div className="p-4 bg-gray-800">
                <h1 className="text-lg font-bold">Ахуенная CRM</h1>
            </div>
            <ul className="flex-1 mt-4 space-y-2 ml-1">
                <li>
                    <Link
                        to="/"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaHome className="mr-3" />
                        Главная
                    </Link>
                </li>
                <li>
                    <Link
                        to="/products"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/products' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaBox className="mr-3" />
                        Товары
                    </Link>
                </li>
                <li>
                    <Link
                        to="/inventory"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/inventory' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaClipboardList className="mr-3" />
                        Инвентаризация
                    </Link>
                </li>
                <li>
                    <Link
                        to="/sales"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/sales' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaStore className="mr-3" />
                        Продажи
                    </Link>
                </li>
                <li>
                    <Link
                        to="/reports"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/reports' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaFileAlt className="mr-3" />
                        Отчёты
                    </Link>
                </li>
                <li>
                    <Link
                        to="/clients"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/clients' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaUsers className="mr-3" />
                        Сотрудники
                    </Link>
                </li>
            </ul>

            <div className="px-4 py-2 mb-4">
                <label className="text-sm font-medium text-gray-300 block mb-2">Предприятие</label>
                <div className="relative">
                    <FaStore className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <select
                        value={selectedStore}
                        onChange={handleStoreChange}
                        className="appearance-none w-full bg-gray-700 text-white py-2 pl-8 pr-10 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        style={{ paddingRight: '2.5rem' }}
                    >
                        <option value="Звенигородская">Звенигородская</option>
                        <option value="Рыбацкое">Рыбацкое</option>
                        <option value="Магазин">Магазин</option>
                    </select>
                    <FaChevronDown className="pointer-events-none absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
            </div>

            <button
                onClick={onLogout}
                className="flex items-center py-2 px-4 hover:bg-gray-700 rounded mt-auto mb-4 ml-1"
            >
                <FaSignOutAlt className="mr-3" />
                Выйти
            </button>
        </div>
    );
};

export default Sidebar;
