import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import ClientsPage from './ClientsPage';
import ReportsPage from './ReportsPage';
import ProductsPage from './products/ProductsPage';
import SalesPage from "./sales/SalesPage";
import InventoryPage from "./inventory/InventoryPage";

const Content = () => {
    return (
        <div className="flex-grow p-6 overflow-auto">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/clients" element={<ClientsPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/sales" element={<SalesPage />} />
                <Route path="/inventory" element={<InventoryPage />} />
            </Routes>
        </div>
    );
};

export default Content;
