import React from 'react';
import { FaCreditCard, FaMoneyBillWave } from 'react-icons/fa';

function PaymentOptions({ onPayment, onMethodSelect, selectedMethod }) {
    const YandexIcon = () => (
        <svg
            version="1.1"
            id="Yandex_Logo"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            style={{ enableBackground: 'new 0 0 1080 1080' }}
            xmlSpace="preserve"
            height="25px"
        >
            <style type="text/css">
                {`.yandex_st0{fill:#FC3F1D;}
        .yandex_st1{fill:#FFFFFF;}`}
            </style>
            <circle className="yandex_st0" cx="540" cy="540" r="538.9" />
            <path
                id="Glyph"
                className="yandex_st1"
                d="M735,878.3H616.7V293.2H564c-96.6,0-147.2,48.3-147.2,120.4c0,81.8,34.9,119.7,107.1,168l59.5,40.1
        l-171,256.5H285.2l153.9-229c-88.5-63.2-138.3-124.9-138.3-229c0-130.1,90.7-218.6,262.4-218.6h171v676.5H735z"
            />
        </svg>
    );

    const OzonIcon = () => (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1080 474.7"
            style={{ enableBackground: 'new 0 0 1080 474.7' }}
            xmlSpace="preserve"
            height="25px"
            width="auto"
        >
            <style type="text/css">
                {`.st0{fill:#FFFFFF;}
        .st1{fill:#005BFF;}`}
            </style>
            <rect className="st0" width="1080" height="474.7" />
            <g>
                <path
                    className="st1"
                    d="M478.3,277.3h-64.7L496.2,168c1.8-2.4,1.3-5.9-1.1-7.7c-0.9-0.7-2.1-1.1-3.3-1.1H372.3
          c-10.5,0-19.1,8.6-19.1,19.1s8.6,19.1,19.1,19.1h53.7l-82.9,109.4c-1.9,2.4-1.4,5.8,1,7.7c1,0.8,2.2,1.2,3.4,1.1h130.7
          c10.5-0.5,18.6-9.5,18.1-20.1c-0.5-9.8-8.3-17.6-18.1-18.1V277.3z M895.9,159.2c-10.5,0-19.1,8.6-19.1,19.1v64.1l-103.2-81.9
          c-2.3-1.9-5.8-1.6-7.7,0.8c-0.8,1-1.2,2.2-1.2,3.5v131.7c0,10.5,8.6,19.1,19.1,19.1s19.1-8.5,19.1-19.1v-64.1l103.2,82
          c2.4,1.9,5.9,1.5,7.8-0.9c0.8-1,1.2-2.2,1.2-3.4V178.3C915,167.7,906.5,159.2,895.9,159.2 M622.8,280.9c-43.7,0-76.3-23-76.3-43.6
          c0-20.6,32.7-43.6,76.3-43.6c43.7,0,76.3,23,76.3,43.6C699.1,257.9,666.6,280.9,622.8,280.9 M622.8,155.5
          c-63.2,0-114.5,36.6-114.5,81.8c0,45.2,51.3,81.8,114.5,81.8s114.5-36.6,114.5-81.8C737.3,192.1,686,155.5,622.8,155.5 M246.9,281
          c-24.1,0-43.7-19.5-43.7-43.6c0-24.1,19.5-43.7,43.6-43.7c24.1,0,43.7,19.5,43.7,43.6v0.1C290.5,261.4,271,280.9,246.9,281
           M246.9,155.5c-45.2,0-81.8,36.6-81.9,81.8c0,45.2,36.6,81.8,81.8,81.9c45.2,0,81.8-36.6,81.9-81.8c0,0,0-0.1,0-0.1
          C328.6,192.1,292,155.5,246.9,155.5"
                />
            </g>
            <rect x="2403.4" width="1080" height="474.7" />
            <g>
                <path
                    className="st0"
                    d="M2881.7,277.3H2817l82.6-109.3c1.8-2.4,1.3-5.9-1.1-7.7c-0.9-0.7-2.1-1.1-3.3-1.1h-119.5
          c-10.5,0-19.1,8.6-19.1,19.1s8.6,19.1,19.1,19.1h53.7l-82.9,109.4c-1.9,2.4-1.4,5.8,1,7.7c1,0.8,2.2,1.2,3.4,1.1h130.7
          c10.5-0.5,18.6-9.5,18.1-20.1c-0.5-9.8-8.3-17.6-18.1-18.1V277.3z M3299.3,159.2c-10.5,0-19.1,8.6-19.1,19.1v64.1L3177,160.4
          c-2.3-1.9-5.8-1.6-7.7,0.8c-0.8,1-1.2,2.2-1.2,3.5v131.7c0,10.5,8.6,19.1,19.1,19.1s19.1-8.5,19.1-19.1v-64.1l103.2,82
          c2.4,1.9,5.9,1.5,7.8-0.9c0.8-1,1.2-2.2,1.2-3.4V178.3C3318.4,167.7,3309.8,159.2,3299.3,159.2 M3026.2,280.9
          c-43.7,0-76.3-23-76.3-43.6c0-20.6,32.7-43.6,76.3-43.6c43.7,0,76.3,23,76.3,43.6C3102.5,257.9,3070,280.9,3026.2,280.9
           M3026.2,155.5c-63.2,0-114.5,36.6-114.5,81.8c0,45.2,51.3,81.8,114.5,81.8s114.5-36.6,114.5-81.8
          C3140.7,192.1,3089.4,155.5,3026.2,155.5 M2650.3,281c-24.1,0-43.7-19.5-43.7-43.6c0-24.1,19.5-43.7,43.6-43.7
          c24.1,0,43.7,19.5,43.7,43.6v0.1C2693.9,261.4,2674.4,280.9,2650.3,281 M2650.3,155.5c-45.2,0-81.8,36.6-81.9,81.8
          c0,45.2,36.6,81.8,81.8,81.9c45.2,0,81.8-36.6,81.9-81.8c0,0,0-0.1,0-0.1C2732,192.1,2695.4,155.5,2650.3,155.5"
                />
            </g>
            <rect x="1191.6" className="st1" width="1080" height="474.7" />
            <g>
                <path
                    className="st0"
                    d="M1669.9,277.3h-64.7l82.6-109.3c1.8-2.4,1.3-5.9-1.1-7.7c-0.9-0.7-2.1-1.1-3.3-1.1h-119.5
          c-10.5,0-19.1,8.6-19.1,19.1s8.6,19.1,19.1,19.1h53.7l-82.9,109.4c-1.9,2.4-1.4,5.8,1,7.7c1,0.8,2.2,1.2,3.4,1.1h130.7
          c10.5-0.5,18.6-9.5,18.1-20.1c-0.5-9.8-8.3-17.6-18.1-18.1V277.3z M2087.5,159.2c-10.5,0-19.1,8.6-19.1,19.1v64.1l-103.2-81.9
          c-2.3-1.9-5.8-1.6-7.7,0.8c-0.8,1-1.2,2.2-1.2,3.5v131.7c0,10.5,8.6,19.1,19.1,19.1s19.1-8.5,19.1-19.1v-64.1l103.2,82
          c2.4,1.9,5.9,1.5,7.8-0.9c0.8-1,1.2-2.2,1.2-3.4V178.3C2106.6,167.7,2098,159.2,2087.5,159.2 M1814.4,280.9
          c-43.7,0-76.3-23-76.3-43.6c0-20.6,32.7-43.6,76.3-43.6c43.7,0,76.3,23,76.3,43.6C1890.7,257.9,1858.1,280.9,1814.4,280.9
           M1814.4,155.5c-63.2,0-114.5,36.6-114.5,81.8c0,45.2,51.3,81.8,114.5,81.8c63.2,0,114.5-36.6,114.5-81.8
          C1928.8,192.1,1877.6,155.5,1814.4,155.5 M1438.4,281c-24.1,0-43.7-19.5-43.7-43.6c0-24.1,19.5-43.7,43.6-43.7
          c24.1,0,43.7,19.5,43.7,43.6v0.1C1482,261.4,1462.5,280.9,1438.4,281 M1438.4,155.5c-45.2,0-81.8,36.6-81.9,81.8
          c0,45.2,36.6,81.8,81.8,81.9c45.2,0,81.8-36.6,81.9-81.8c0,0,0-0.1,0-0.1C1520.2,192.1,1483.6,155.5,1438.4,155.5"
                />
            </g>
        </svg>
    );


    const paymentMethods = [
        {id: 'cash', label: 'Наличными', icon: <FaMoneyBillWave/>},
        {id: 'card', label: 'Картой', icon: <FaCreditCard/>},
        {id: 'yandex', label: 'Яндекс Маркет', icon: <YandexIcon/>},
        {id: 'ozon', label: 'OZON', icon: <OzonIcon/>}
    ];

    return (
        <div className="mt-4">
            <h3 className="text-lg font-medium mb-4">Выберите тип оплаты</h3>
            <div className="flex flex-col space-y-2">
                {paymentMethods.map((method) => (
                    <button
                        key={method.id}
                        onClick={() => onMethodSelect(method.id)}
                        className={`flex items-center justify-center w-full p-4 text-lg font-semibold rounded-lg shadow-md transition-colors
              ${selectedMethod === method.id ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                    >
                        {method.icon}
                        <span className="ml-3">{method.label}</span>
                    </button>
                ))}
            </div>
            <button
                onClick={onPayment}
                className="mt-4 w-full p-4 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600"
            >
                Провести оплату
            </button>
        </div>
    );
}

export default PaymentOptions;
