import React from 'react';

const ProductSidebar = ({
                            isAddProductSidebarOpen,
                            newProduct,
                            handleAddProductChange,
                            handleAddProduct,
                            setIsAddProductSidebarOpen
                        }) => {
    return (
        <div className={`fixed top-0 right-0 w-80 h-full bg-white border-l shadow-lg transform ${isAddProductSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform`}>
            <div className="p-4">
                <h3 className="text-xl font-semibold mb-4">Добавить новый товар</h3>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Название товара</label>
                    <input
                        type="text"
                        name="productName"
                        placeholder="Название товара"
                        value={newProduct.productName}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Розничная цена</label>
                    <input
                        type="number"
                        name="retailPrice"
                        placeholder="Розничная цена"
                        value={newProduct.retailPrice}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Закупочная цена</label>
                    <input
                        type="number"
                        name="stockPrice"
                        placeholder="Закупочная цена"
                        value={newProduct.stockPrice}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Шаблон зарплаты</label>
                    <input
                        type="number"
                        name="salary"
                        placeholder="Шаблон зарплаты"
                        value={newProduct.salary}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Количество на складе</label>
                    <input
                        type="number"
                        name="amountInStock"
                        placeholder="Количество на складе"
                        value={newProduct.amountInStock}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Штрихкод</label>
                    <input
                        type="text"
                        name="barcode"
                        placeholder="Штрихкод"
                        value={newProduct.barcode}
                        onChange={handleAddProductChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <button
                    onClick={handleAddProduct}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Добавить
                </button>
                <button
                    onClick={() => setIsAddProductSidebarOpen(false)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 ml-2"
                >
                    Закрыть
                </button>
            </div>
        </div>
    );
};

export default ProductSidebar;
