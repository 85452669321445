import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {FaRedo, FaSort, FaSortDown, FaSortUp} from 'react-icons/fa';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from "../../useAuth";

import ProductTable from './ProductTable';
import ProductSidebar from './ProductSidebar';
import SearchBar from './SearchBar';
import ErrorAlert from './ErrorAlert';
import LoadingSpinner from './LoadingSpinner';
import {Link} from "react-router-dom";

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'productName', direction: 'ascending' });
    const [columns, setColumns] = useState([
        { id: 'productName', label: 'Название' },
        { id: 'retailPrice', label: 'Цена' },
        { id: 'amountInStock', label: 'Товарный остаток' },
        { id: 'barcode', label: 'Штрихкод' },
        { id: 'brand', label: 'Бренд' },
        { id: 'category', label: 'Категория' },
    ]);
    const [isAddProductSidebarOpen, setIsAddProductSidebarOpen] = useState(false);
    const [newProduct, setNewProduct] = useState({
        productName: '',
        retailPrice: 0,
        stockPrice: 0,
        salary: 1,
        amountInStock: 0,
        author: 1,
        category: null,
        brand: null,
        barcode: '',
        allowedForSale: 1
    });

    const { getToken } = useAuth();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if (!token) return;

        const axiosInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axios.interceptors.request.eject(axiosInterceptor);
        };
    }, [token]);

    const fetchProducts = () => {
        setLoading(true);
        setError('');
        axios.get(API_ENDPOINTS.PRODUCTS)
            .then(response => setProducts(response.data))
            .catch(error => {
                console.error('Ошибка при загрузке товаров:', error);
                setError('Не удалось загрузить данные о товарах. Попробуйте позже.');
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (token) {
            fetchProducts();
        }
    }, [token]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredProducts = products.filter(
        (product) =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (product.barcode && product.barcode.includes(searchTerm))
    );

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedProducts = [...products].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            if (!isNaN(aValue) && !isNaN(bValue)) {
                return direction === 'ascending' ? aValue - bValue : bValue - aValue;
            }

            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setProducts(sortedProducts);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
        }
        return <FaSort />;
    };

    const moveColumn = useCallback((dragIndex, hoverIndex) => {
        const newColumns = [...columns];
        const [movedColumn] = newColumns.splice(dragIndex, 1);
        newColumns.splice(hoverIndex, 0, movedColumn);
        setColumns(newColumns);
    }, [columns]);

    const handleAddProductChange = (e) => {
        const { name, value } = e.target;
        setNewProduct({
            ...newProduct,
            [name]: value
        });
    };

    const handleAddProduct = () => {
        axios.post(API_ENDPOINTS.ADD_PRODUCT, newProduct)
            .then(response => {
                setProducts([...products, response.data]);
                setIsAddProductSidebarOpen(false);
            })
            .catch(error => console.error('Ошибка при добавлении товара:', error));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex h-full">
                <ProductSidebar
                    isAddProductSidebarOpen={isAddProductSidebarOpen}
                    newProduct={newProduct}
                    handleAddProductChange={handleAddProductChange}
                    handleAddProduct={handleAddProduct}
                    setIsAddProductSidebarOpen={setIsAddProductSidebarOpen}
                />
                <div className="flex-grow p-6 flex flex-col">
                    <h2 className="text-2xl font-semibold mb-4">Товары</h2>
                    <div className="flex justify-between items-center mb-4">
                        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
                        <div className="flex items-center">
                            <button
                                onClick={fetchProducts}
                                className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400"
                            >
                                <FaRedo />
                            </button>
                            <button
                                onClick={() => setIsAddProductSidebarOpen(true)}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ml-4 mr-4"
                            >
                                Добавить товар
                            </button>
                            <Link to="/inventory">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Начать инвентаризацию
                                </button>
                            </Link>
                        </div>
                    </div>
                    <ErrorAlert error={error} />
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="flex-1 overflow-y-auto">
                            <ProductTable
                                columns={columns}
                                products={filteredProducts}
                                handleSort={handleSort}
                                getSortIcon={getSortIcon}
                                moveColumn={moveColumn}
                            />
                        </div>
                    )}
                </div>
            </div>
        </DndProvider>
    );
};

export default ProductsPage;