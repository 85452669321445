import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const LoadingSpinner = () => {
    return (
        <div className="flex justify-center items-center h-64">
            <FaSpinner className="text-blue-500 animate-spin text-4xl" />
        </div>
    );
};

export default LoadingSpinner;
