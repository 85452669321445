import { useState } from 'react';
import Cookies from 'js-cookie';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return Boolean(Cookies.get('authToken'));
    });

    const login = (token) => {
        Cookies.set('authToken', token, {
            expires: 7, // токен будет действителен 7 дней
            sameSite: 'Strict',
            secure: false
        });
        setIsAuthenticated(true);
    };

    const logout = () => {
        Cookies.remove('authToken');
        setIsAuthenticated(false);
    };

    const getToken = () => {
        return Cookies.get('authToken');
    };

    return { isAuthenticated, login, logout, getToken };
};

export default useAuth;
