import React from 'react';

const Home = () => {
    return (
        <div>
            <h2>Главная</h2>
            <p>Тут пока очень пусто</p>
        </div>
    );
};

export default Home;
