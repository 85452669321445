import React from 'react';
import DraggableHeader from './DraggableHeader';
import { FaPencilAlt } from 'react-icons/fa';

const ProductTable = ({ columns, products, handleSort, getSortIcon, moveColumn }) => {
    return (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg h-full flex flex-col">
            <div className="overflow-y-auto flex-grow">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 w-1 bg-gray-50"></th>
                        {columns.map((column, index) => (
                            <DraggableHeader
                                key={column.id}
                                id={column.id}
                                index={index}
                                moveColumn={moveColumn}
                            >
                                    <span onClick={() => handleSort(column.id)}>
                                        {column.label}
                                        <span className="ml-2 inline-flex items-center">{getSortIcon(column.id)}</span>
                                    </span>
                            </DraggableHeader>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {products.map((product) => (
                        <tr key={product.barcode || product.id} className="hover:bg-gray-100">
                            <td className="py-4 px-6 text-sm text-gray-500">
                                <FaPencilAlt className="text-blue-500 cursor-pointer"/>
                            </td>
                            {columns.map((column) => (
                                <td key={column.id} className="py-4 px-6 text-sm text-gray-500">
                                    {product[column.id]}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductTable;