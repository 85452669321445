import React from 'react';

function SuggestionsList({ suggestions, onSelect, searchTerm, loading }) {
    if (searchTerm.trim() === '' || loading) {
        return null;
    }

    if (suggestions.length === 0) {
        return (
            <p className="p-4 text-gray-500">Ничего не найдено.</p>
        );
    }

    return (
        <div className="mt-4 bg-white rounded-lg shadow max-h-screen overflow-auto">
            {suggestions.map((suggestion) => (
                <div
                    key={suggestion.id}
                    onClick={() => onSelect([suggestion])}  // Ensure onSelect gets an array
                    className="p-4 border-b cursor-pointer hover:bg-gray-200"
                >
                    <p className="font-medium">{suggestion.productName}</p>
                    <p className="text-sm text-gray-500">
                        Стоимость: {parseFloat(suggestion.retailPrice).toFixed(2)}₽
                    </p>
                </div>
            ))}
        </div>
    );
}

export default SuggestionsList;
