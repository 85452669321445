import React, { useState, useEffect, useCallback, useRef } from 'react';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';

function AddProductRow({ onAddProduct }) {
    const [newProduct, setNewProduct] = useState({ name: '', barcode: '', quantity: 1 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const barcodeInputRef = useRef(null);
    const quantityInputRef = useRef(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchProductSuggestions = useCallback(async (barcode) => {
        if (!token) return;

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${barcode}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch suggestions');
            }

            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            console.error('Error fetching product suggestions:', error);
            setError('Ошибка при получении предложений. Попробуйте снова.');
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        if (newProduct.barcode.trim() !== '') {
            fetchProductSuggestions(newProduct.barcode);
        } else {
            setSuggestions([]);
        }
    }, [newProduct.barcode, fetchProductSuggestions]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSelectSuggestion = (suggestion) => {
        setNewProduct({
            ...newProduct,
            name: suggestion.productName,
            barcode: suggestion.barcode,
        });
        setSuggestions([]);
        barcodeInputRef.current.focus();
    };

    const handleAdd = () => {
        if (newProduct.barcode.trim() !== '' && newProduct.name.trim() !== '') {
            onAddProduct(newProduct);
            setNewProduct({ name: '', barcode: '', quantity: 1 });
            setError(null);
            setSuggestions([]);
        } else {
            setError('Товар с таким штрихкодом не найден в базе данных');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAdd();
        }
    };

    useEffect(() => {
        const handleFocusCheck = () => {
            const activeElement = document.activeElement;
            const allInputs = document.querySelectorAll('input, textarea, select');

            const isActiveElementInInputs = Array.from(allInputs).some(el => el === activeElement);

            if (!activeElement ||
                (activeElement === document.body) ||
                !isActiveElementInInputs
            ) {
                barcodeInputRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleFocusCheck);

        return () => {
            window.removeEventListener('keydown', handleFocusCheck);
        };
    }, []);


    return (
        <div className="relative flex flex-col mt-4 p-4">
            <div className="flex items-center mb-4">
                <input
                    type="text"
                    name="barcode"
                    placeholder="Отсканируйте или введите штрихкод"
                    value={newProduct.barcode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="border p-2 mr-2 rounded-md w-2/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ref={barcodeInputRef}
                />
                <input
                    type="number"
                    name="quantity"
                    placeholder="Количество"
                    value={newProduct.quantity}
                    onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })}
                    onKeyDown={handleKeyDown}
                    className="border p-2 mr-2 rounded-md w-1/4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ref={quantityInputRef}
                />
                <button
                    onClick={handleAdd}
                    className="bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600 transition duration-300 w-40"
                    disabled={loading}
                    type="submit"
                >
                    {loading ? 'Поиск...' : 'Добавить'}
                </button>
            </div>
            {error && (
                <div className="absolute top-full left-0 mt-2 bg-red-100 border border-red-300 rounded-lg shadow-lg w-full z-20 p-3">
                    <div className="text-red-700">{error}</div>
                </div>
            )}
            {suggestions.length > 0 && (
                <div className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-20 max-h-60 overflow-y-auto">
                    {suggestions.map((suggestion, index) => (
                        <div
                            key={index}
                            className="p-3 cursor-pointer hover:bg-gray-100 border-b last:border-b-0"
                            onClick={() => handleSelectSuggestion(suggestion)}
                        >
                            <div className="text-gray-800 text-lg font-semibold">
                                {suggestion.productName}
                            </div>
                            <div className="text-gray-500 text-sm">
                                Штрихкод: {suggestion.barcode}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AddProductRow;
