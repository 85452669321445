import React from 'react';
import { FaTrash } from 'react-icons/fa';

function Cart({ items, onRemove }) {
    return (
        <div className="flex-grow">
            <h2 className="text-xl font-semibold mb-4">Корзина</h2>
            {items.length === 0 ? (
                <p className="text-gray-500">Не выбран ни один товар.</p>
            ) : (
                items.map((item) => (
                    <div key={item.id} className="flex justify-between items-center p-2 border-b">
                        <div>
                            <p className="font-medium">{item.productName}</p>
                            <p className="text-sm text-gray-500">Количество: {item.quantity}</p>
                        </div>
                        <div className="flex items-center">
                            <p className="mr-4">{(item.retailPrice * item.quantity).toFixed(2)}₽</p>
                            <FaTrash
                                className="text-red-500 cursor-pointer"
                                onClick={() => onRemove(item)}
                            />
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default Cart;
