//const BASE_URL = 'http://localhost:8000/api';
const BASE_URL = 'https://api.gaprix.ru/api';

export const API_ENDPOINTS = {
    PRODUCTS: `${BASE_URL}/products`,
    ADD_PRODUCT: `${BASE_URL}/newproduct`,
    INVENTORY: `${BASE_URL}/inventory`,
    LOGIN: `${BASE_URL}/login`,
    SELL: `${BASE_URL}/sell`,
    PRODUCTS_INVENTORY: `${BASE_URL}/inventory/check`,
};
