import React, { useState } from 'react';

function InventoryRow({ product, index, onUpdateProduct }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedProduct, setEditedProduct] = useState(product);

    const handleEditChange = (e) => {
        setEditedProduct({
            ...editedProduct,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        onUpdateProduct(index, editedProduct);
        setIsEditing(false);
    };

    return (
        <tr className={`hover:bg-gray-100 ${product.issue ? 'bg-red-100' : ''}`}>
            <td className="py-4 px-6 text-sm text-gray-800">
                {isEditing ? (
                    <input
                        type="text"
                        name="name"
                        value={editedProduct.name}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded-md"
                    />
                ) : (
                    product.name
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800">
                {isEditing ? (
                    <input
                        type="text"
                        name="barcode"
                        value={editedProduct.barcode}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded-md"
                    />
                ) : (
                    product.barcode
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800">
                {isEditing ? (
                    <input
                        type="number"
                        name="quantity"
                        value={editedProduct.quantity}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded-md"
                    />
                ) : (
                    product.quantity
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800">
                {isEditing ? (
                    <button
                        onClick={handleSave}
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                        Сохранить
                    </button>
                ) : (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                    >
                        Изменить
                    </button>
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800">
                {product.issue && (
                    <span className="text-red-600">{product.issue}</span>
                )}
            </td>
        </tr>
    );
}

export default InventoryRow;
